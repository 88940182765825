
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"
import { DiJavascript } from "react-icons/di";
import { SiMongodb, SiExpress, SiSpring, SiHibernate, SiSpringboot, SiJenkins, SiPython, SiMysql, SiPostgresql, SiOracle, SiC, SiCplusplus,SiKubernetes } from "react-icons/si";
import { FaReact, FaJava,FaNodeJs, FaGitlab, FaDocker, FaGithub } from 'react-icons/fa';

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>My Core Competencies lies in,<br></br>Software development, mobile app development, database architecture design, database modeling,software design patterns, microservices, CI/CD, problem-solving, optimizing code to reduce time and
                space complexity, researching the best-suited libraries, packages, and updates, implementing the same, taking care of application security, designing and implementing new features, writing optimal-
                quality code, writing test cases, developing REST APIs, designing and developing beautiful UI components with efficient functionalities, integrating the same, bug fixing, and maintaining quality
                code and documentation.</p>
              <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                <div className="item">
                  <div className="icon-container">
                    <FaReact className="icon" />
                    <FaNodeJs className="icon" />
                    <SiExpress className="icon" />
                    <SiMongodb className="icon" />
                  </div>
                  <h5 className="stack-title">Mern Stack</h5>

                </div>
                <div className="item">
                  <div className="icon-container">
                    <FaJava className="icon" />
                    <SiSpring className="icon" />
                    <SiSpringboot className="icon" />
                    <SiHibernate className="icon" />
                  </div>
                  <h5 className="stack-title">Java Stack</h5>
                </div>
                <div className="item">
                  <div className="icon-container">
                    <SiMysql className="icon" />
                    <SiPostgresql className="icon" />
                    <SiOracle className="icon" />
                  </div>
                  <h5 className="stack-title">Databases</h5>
                </div>
                <div className="item">
                  <div className="icon-container">
                    <DiJavascript className="icon" />
                    <FaJava className="icon" />
                    <SiPython className="icon" />
                    <SiC className="icon" />
                    <SiCplusplus className="icon" />
                  </div>
                  <h5 className="stack-title">Programming Languages</h5>
                </div>
                <div className="item">
                  <div className="icon-container">
                    <SiJenkins className="icon" />
                    <FaGitlab className="icon" />
                    <FaDocker className="icon" />
                    <FaGithub className="icon" />
                  </div>
                  <h5 className="stack-title">DevOps tools</h5>
                </div>

              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}






